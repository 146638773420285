<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search meeting"
            type="text"
          />
          <b-button
            v-b-modal.scheduleMeetingModal
            variant="primary mr-1"
          >
            <feather-icon icon="ClockIcon" />
            Schedule Session
          </b-button>
          <b-button
            v-b-modal.addSlotModalold
            variant="outline-success mr-1"
          >
            <feather-icon icon="PlusIcon" />
            Add Slot
          </b-button>
          <b-button
            v-b-modal.manageSlotModal
            variant="outline-secondary mr-1"
          >
            <feather-icon icon="CalendarIcon" />
            Manage Slot
          </b-button>
          <b-button
            v-if="$route.params.mid"
            variant="outline-primary mr-1"
            @click="$router.replace(`/programs/${$route.params.id}/manage#Sessions`)"
          >
            Show All
          </b-button>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span
            v-if="props.column.field === 'action'"
            class="d-flex"
          >
            <b-button
              v-b-tooltip="'Update Links'"
              class="mr-50"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
            >
              <feather-icon
                icon="LinkIcon"
              />
            </b-button>
            <b-button
              v-b-tooltip="'Add Participants'"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('addParticipants')"
            >
              <feather-icon
                icon="UserPlusIcon"
              />
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'buttons'">

            <b-button
              :href="prependHttps(props.row.meeting_link)"
              class="mb-25 mr-25"
              size="sm"
              target="_blank"
              variant="outline-primary"
            >Join meeting</b-button>
            <b-button
              v-if="props.row.recording_link"
              :href="prependHttps(props.row.recording_link)"
              size="sm"
              target="blank"
              variant="outline-primary"
            >View recording</b-button>
          </span>

          <span v-else-if="props.column.label === 'Meeting'">

            {{ props.row.title }}<br> <b-badge
              variant="light-primary"
              class="mt-25"
            >{{ props.row.type }}</b-badge>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
              class="mb-25"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template
          v-slot:column-filter="{ column, updateFilters }"
        >
          <span v-if="column.label === 'Operation'">
            <treeselect
              :append-to-body="true"
              :normalizer="filterNormalizer"
              :options="operations"
              class="form-control p-0 border-0"
              placeholder="Select Phase, Subphase or Activities"
              style="border-collapse: separate"
              @input="(val) => updateFilters(column, val)"
            />
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
    <b-modal
      id="scheduleMeetingModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Schedule Session"
      @ok="addMeeting"
    >
      <b-row>

        <b-col md="6">
          <b-form-group
            label="Module"
            label-for="operation"
          >
            <treeselect
              id="operation"
              v-model="operationsId"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Module"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Purpose"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Meeting title"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Enter Description"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="startupParticipants"
              :get-option-label="option => option.title"
              :options="startups"
              :reduce="option => option.id"
              multiple
              placeholder="Select Startups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Partners"
            label-for="selectPartners"
          >
            <v-select
              id="selectPartners"
              v-model="partnerParticipants"
              :get-option-label="option => option.full_name"
              :options="partners"
              :reduce="option => option.id"
              multiple
              placeholder="Select Partners"
            />
          </b-form-group>
        </b-col>

        <!-- start date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Start date & time"
            label-for="StartDateAndTime"
          >
            <flat-pickr
              id="StartDateAndTime"
              v-model="beginTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              class="form-control"
              placeholder="Select start date & tIme"
            />
          </b-form-group>
        </b-col>
        <!-- end date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="End date & time"
            label-for="EndDateAndTime"
          >
            <flat-pickr
              id="EndDateAndTIme"
              v-model="endTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: beginTime }"
              class="form-control"
              placeholder="Select end date & tIme"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addSlotModalold"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Add Slot"
      @ok="addMeeting"
    >
      <b-row>

        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Select Date"
            label-for="slotDate"
          >
            <flat-pickr
              id="slotDate"
              v-model="slotdate"
              :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
              class="form-control"
              placeholder="Select Date"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
        >
          <h6>Scheduled Slots</h6>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
          class="d-flex mb-2"
          style="flex-wrap: wrap;"
        >
          <span
            class="bg-primary text-white d-flex align-items-center"
            style="border-radius: 200px; padding: 2px 8px;"
          >
            16:00 - 18:00
            <feather-icon
              icon="XCircleIcon"
              style="margin-left:4px; cursor: pointer;"
            />
          </span>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
        >
          <b-row>
            <b-col md="11">
              <b-row>
                <b-col
                  md="6"
                >
                  <b-form-group
                    class="mb-2"
                    label="Start time"
                    label-for="StartDateAndTime"
                  >
                    <flat-pickr
                      id="StartDateAndTime"
                      v-model="slotbeginTime"
                      :config="{ enableTime: true, dateFormat: 'h:i K', noCalendar: true}"
                      class="form-control"
                      placeholder="Select start tIme"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                >
                  <b-form-group
                    class="mb-2"
                    label="End time"
                    label-for="EndDateAndTime"
                  >
                    <flat-pickr
                      id="EndDateAndTIme"
                      v-model="slotendTime"
                      :config="{ enableTime: true,dateFormat: 'h:i K', minDate: slotbeginTime, noCalendar: true }"
                      class="form-control"
                      placeholder="Select end tIme"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="1">
              <span
                class="mt-2 d-flex align-items-center bg-primary text-white justify-content-center"
                style="padding-top: 0.786rem; padding-bottom: 0.786rem; border-radius: 0.358rem; cursor: pointer;"
                @click="addtoselectslot()"
              >
                <feather-icon icon="PlusIcon" />
              </span>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
        >
          <h6>Selected Slots</h6>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
          class="d-flex mb-2"
          style="flex-wrap: wrap;"
        >
          <span
            v-for="(slots, index) in selectedslots"
            :key="index"
            class="bg-warning text-white d-flex align-items-center mr-1"
            style="border-radius: 200px; padding: 2px 8px;"
          >
            {{ slots.begintime }} - {{ slots.endtime }}
            <feather-icon
              icon="XCircleIcon"
              style="margin-left:4px; cursor: pointer;"
              @click="removeselectedslot(index)"
            />
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="manageSlotModal"
      ok-title="Okay"
      ok-only
      no-close-on-backdrop
      size="xl"
      title="Manage Slots"
      @ok="addMeeting"
    >
      <b-row>
        <b-col md="12">
          <calendar />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Save"
      no-close-on-backdrop
      title="Update Meeting Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Meeting Link"
            label-for="updateMeetingLink"
          >
            <b-form-input
              id="updateMeetingLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addParticipants"
      ok-only
      ok-title="Save"
      title="Add Participants"
      no-close-on-backdrop
      @ok="updateParticipants"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="startupParticipants"
              :get-option-label="option => option.title"
              :options="startups"
              :reduce="option => option.id"
              multiple
              placeholder="Select Startups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Partners"
            label-for="selectPartners"
          >
            <v-select
              id="selectPartners"
              v-model="partnerParticipants"
              :get-option-label="option => option.full_name"
              :options="partners"
              :reduce="option => option.id"
              multiple
              placeholder="Select Partners"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Calendar from '@/views/incubator-portal/programs/components/calendar/Slotcalendar.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    // FormBuilder,
    Calendar,
    vSelect,
    Treeselect,
    flatPickr,
  },
  data() {
    return {
      title: '',
      type: '',
      slotdate: '',
      slotbeginTime: '',
      slotendTime: '',
      selectedslots: [],
      beginTime: '',
      endTime: '',
      meetingLink: '',
      description: '',
      operationsId: null,
      status: '',
      mutationLoading: false,
      participants: [],
      meetingTypeOptions: [
        {
          label: 'webinar',
          id: 1,
        },
        {
          label: 'one-on-one',
          id: 2,
        },
        {
          label: 'multi participant',
          id: 3,
        },
      ],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Meeting',
          field(row) {
            return `${row.title} - ${row.type}`
          },
          tdClass: 'text-capitalize',
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Time',
          width: '10rem',
          field(row) {
            if (!row.begin_time) return '-'
            return `${new Date(row.begin_time)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
                hour12: false,
              })} - ${new Date(row.end_time)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
                hour12: false,
              })}`
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Links',
          field: 'buttons',
          width: '11rem',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      selectedOperation: null,
      startups: [],
      partners: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Scheduled: 'light-primary',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    startupParticipants: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return this.rows[this.selectedRow].programs_meetparticipanttables.map(e => e.organization_id_id)
          .filter(e => e)
      },
      set(e) {
        this.rows[this.selectedRow].programs_meetparticipanttables = this.rows[this.selectedRow].programs_meetparticipanttables.filter(i => i.user_id_id !== null)
        this.rows[this.selectedRow].programs_meetparticipanttables.push(...e.map(val => ({
          organization_id_id: val,
          user_id_id: null,
          schedule_id_id: this.rows[this.selectedRow].id,
        })))
      },
    },
    partnerParticipants: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return this.rows[this.selectedRow].programs_meetparticipanttables.map(e => e.user_id_id)
          .filter(e => e)
      },
      set(e) {
        this.rows[this.selectedRow].programs_meetparticipanttables = this.rows[this.selectedRow].programs_meetparticipanttables.filter(i => i.organization_id_id !== null)
        this.rows[this.selectedRow].programs_meetparticipanttables.push(...e.map(val => ({
          organization_id_id: null,
          user_id_id: val,
          schedule_id_id: this.rows[this.selectedRow].id,
        })))
      },
    },

  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },

    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    addtoselectslot() {
      if (this.slotbeginTime) {
        if (this.slotendTime) {
          this.selectedslots.push({
            begintime: this.slotbeginTime,
            endtime: this.slotendTime,
          })
          this.slotbeginTime = ''
          this.slotendTime = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Select End Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Begin Time',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeselectedslot(index) {
      this.selectedslots.splice(index, 1)
    },
    addMeeting() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_scheduletable_one(object: {begin_time: "${this.beginTime}", end_time: "${this.endTime}", description: "${this.description}", operations_id_id: ${this.operationsId}, program_id_id: "${this.$route.params.id}", status: "Scheduled", title: "${this.title}", type: "${this.type}"}) {
            id
          }
        }`,
        update: (store, { data: { insert_programs_scheduletable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_scheduletable_one.id ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_scheduletable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_scheduletable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          // this.$apollo.mutate({
          //   mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          //     delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${update_programs_scheduletable_by_pk.id}}}) {
          //       affected_rows
          //     }
          //     insert_programs_meetparticipanttable(objects: $objects) {
          //       affected_rows
          //     }
          //   }`,
          //   variables: {
          //     objects: this.rows[this.selectedRow].programs_meetparticipanttables,
          //   },
          //   update: () => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: 'Updated successfully',
          //         icon: 'CheckIcon',
          //         variant: 'success',
          //       },
          //     })
          //     this.$apollo.queries.rows.refetch()
          //     this.mutationLoading = false
          //   },
          // })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_programs_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_scheduletable(order_by: {begin_time: desc}, where: {program_id_id: {_eq: ${this.$route.params.id}} ${this.$route.params.mid ? `, operations_id:{_eq: ${this.$route.params.mid}}` : ''}}) {
            id
            title
            type
            begin_time
            end_time
            meeting_link
            recording_link
            programs_operationstable {
              title
            }
                       status
            programs_meetparticipanttables {
              schedule_id_id
              organization_id_id
              user_id_id
            }
          }
        }`
      },
      update: data => data.programs_scheduletable,
    },

    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "SubPhase"}}) {
                  id
                  title
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
    partners: {
      query() {
        return gql`{
          programs_partnerstable(where: {program_id: {_eq: ${this.$route.params.id} }}) {
            users_customuser {
              id
              full_name
            }
          }
        }`
      },
      update: data => data.programs_partnerstable.map(e => e.users_customuser),
    },
    startups: {
      query() {
        return gql`{
          programs_startupparticipants(where: {program_id: {_eq:  ${this.$route.params.id} }}) {
            programs_applicantstable {
              users_organizationtable {
                title
                id
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants.map(e => e.programs_applicantstable.users_organizationtable),
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
